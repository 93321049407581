body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.primary {
  color: #7edc91;
}

.secondary {
  color: #25a5a7;
}

.primary-bg {
  background-color: #7edc91;
}

.secondary-bg {
  background-color: #25a5a7;
}

.dark {
  background-color: #4a3546;
}

.btn-primary {
  background-color: #7edc91;
  border-color: #25a5a7;
  background-image: linear-gradient(to bottom, #25a5a7 0%, #7edc91 100%);
}



::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #fff;
}

::-webkit-scrollbar
{
  width: 6px;
  height: 6px;
	background-color: #fff;
}

::-webkit-scrollbar-thumb
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #ddd;
}

