html {
  background-color: #4a3546!important;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.container {
  position: absolute;
  top: calc(50% - 200px);
  left: 0;
  height: 450px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: calc(50% - 200px);
  width: 100%;
  padding-bottom: 50px;
}

.container::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #fff;
}

.container::-webkit-scrollbar
{
  width: 6px;
  height: 15px;
	background-color: #fff;
}

.container::-webkit-scrollbar-thumb
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #351f31;
}



.container-after {
  content: "";
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  box-shadow: inset 0 0 50px 40px #4a3546,
    inset 0 0 10px 10px #4a3546;
    pointer-events: none;
}

.scrolling-content {
  width: 1000px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-right: 100px;
}
