.panel {
    width: 300px;
    float: left;
    text-align: left;
    padding: 20px;
    height: 100%;
    overflow-y: scroll;
    overflow-x:hidden;
}

.panel .heading {
    background-color: #4a3546;
    margin: -21px;
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #fff;
    padding: 10px;
    padding-left: 20px;
}


.image {
    float: right;
    width: calc(100% - 300px);
    position: relative;
    height: 100%;
    box-shadow: inset 0 0 100px #4a3546;
}

.image .image-innner {
    max-width: calc(100% - 40px);
    max-height: calc(100% - 40px);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 200ms ease;
}

.imglogic {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
}

.imglogic::after {
    content: "";
    clear: both;
    display: block;
}

.bounce-loader > div {
    margin: 0 auto!important;
}

.image > .icon {
    font-size: 50px;
    color: #fff;
    text-shadow: 0 0 5px rgba(0,0,0,0.3);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.image > .left {
    left: 10px;
}

.image > .right {
    right: 10px;
}

.image-innner .bounce-loader {
    position: absolute;
    pointer-events: none;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}


.image-innner .bounce-loader > div {
    position: absolute;
    pointer-events: none;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 300;
}

.image-innner .bounce-loader::after {
    transition: all 200ms ease;
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
    pointer-events: none;
    background-color: rgba(0,0,0,0.7);
}

.image-innner .bounce-loader {
    display: none;
}

.image-innner .bounce-loader.loading {
    display: block;
}

.image-innner .bounce-loader p {
    position: absolute;
    text-align: center;
    text-shadow: 0 0 4px rgba(0,0,0,0.3);
    color: #fff;
    z-index: 100;
    overflow: hidden;
    left: 0;
    right: 0;
    bottom: 0;
}

.imglogic {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1000;
}